import React from "react";
import { viewports, colors } from "../../style-vars";

const StepWithBigNumberStyles = () => (
  <style jsx global>{`
    .swbn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 80px;
    }

    .swbn__step {
      flex: 0 1 40%;
      display: flex;
      align-items: baseline;
      z-index: 2;
    }
    .swbn__step--number {
      font-size: 444px;
      font-weight: 700;
      color: #090d12;
      line-height: 110%;
    }
    .swbn.odd .swbn__step--number {
      color: ${colors.primary};
    }
    .swbn__step--label {
      font-size: 32px;
      font-weight: 700;
      color: #090d12;
      transform: rotate(270deg) translateX(25px);
      margin-left: -30px;
    }
    .swbn__step--label-dot {
      color: ${colors.primary};
    }

    .swbn__info {
      flex: 0 1 60%;
      max-width: 375px;
      //margin-left: 114px;
      margin-top: -70px;
      padding: 0 24px;
    }
    .swbn.odd .swbn__info {
      //margin-left: 0;
      //margin-right: 114px;
    }
    .swbn__info--title {
      font-weight: 700;
      font-size: 32px;
      color: #090d12;
    }
    .swbn__info--description {
      font-weight: normal;
      font-size: 16px;
      color: #445469;
      line-height: 160%;
      margin-top: 24px;
    }

    @media ${viewports.mdOrBigger} {
      .swbn {
        flex-direction: row;
        margin-bottom: 0;
      }
      .swbn.odd {
        flex-direction: row-reverse;
      }

      .swbn__info {
        margin-left: 114px;
      }
      .swbn.odd .swbn__info {
        margin-left: 0;
        margin-right: 114px;
      }
    }
  `}</style>
);

export default StepWithBigNumberStyles;
