import React from "react";
import { viewports, colors } from "../../style-vars";

const EnterprisePartnersStyles = () => (
  <style jsx global>{`
    .enterprise-partners main {
      margin-top: -75px !important;
    }
    .enterprise-partners header.ant-layout-header {
      width: 100%;
      max-width: 100%;
    }

    .ep-top {
      position: relative;
      background: #090d12;
      width: 100vw;
      //min-height: calc(100vh + 75px);
      padding: 0 16px;
    }
    .ep-top__logos {
      margin-top: 160px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    .ep-top__logos--spoton {
      width: 110px;
      height: auto;
      margin-top: 2px;
    }
    .ep-top__logos--sign {
      transform: rotate(45deg);
      color: #1769ff;
      font-size: 30px;
      font-weight: 300;
      margin: 0 10px;
    }

    h1.ant-typography.ep-top__title {
      text-align: center;
      color: ${colors.white} !important;
      font-size: 42px !important;
      margin-top: 24px;
    }
    .ep-top__title-description {
      color: ${colors.white};
      font-size: 20px;
      max-width: 1070px;
      text-align: center;
      margin: 24px auto;
      z-index: 2;
      position: relative;
    }

    .ep-top__form-wrapper {
      position: relative;
      margin: 110px auto 0;
      max-width: 800px;
      z-index: 5;
    }
    .ep-top__form-wrapper .lead-form-wrapper {
      margin-bottom: 0 !important;
    }

    .ep-top__spot-background {
      position: absolute;
      left: 50%;
      bottom: -85px;
      margin-left: -421px;
      z-index: 1;
    }
    .ep-top__background-left {
      display: none;
      z-index: 1;
    }
    .ep-top__background-right {
      display: none;
      z-index: 1;
    }

    .ep-steps {
      position: relative;
      margin-top: 110px;
    }
    .ep-steps__connection-top {
      display: none;
    }
    .ep-steps__connection-bottom {
      display: none;
    }

    @media ${viewports.mdOrBigger} {
      .ep-top {
        padding: 0 40px;
      }
    }

    @media ${viewports.mdOrBigger} {
      .ep-steps__connection-top {
        display: block;
        position: absolute;
        top: 330px;
        left: 280px;
        z-index: 1;
      }
      .ep-steps__connection-bottom {
        display: block;
        position: absolute;
        top: 850px;
        left: 160px;
        z-index: 1;
      }
      .ep-top__background-left {
        display: block;
        position: absolute;
        left: -80px;
        bottom: 300px;
      }
    }

    @media ${viewports.lgOrBigger} {
      .ep-top__background-left {
        //display: block;
        //position: absolute;
        left: -50px;
        bottom: 50px;
      }
    }

    @media ${viewports.xlOrBigger} {
      .ep-top__background-left {
        left: 0;
      }
      .ep-top__background-right {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  `}</style>
);

export default EnterprisePartnersStyles;
