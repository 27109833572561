import React, { useEffect } from "react";
import { Typography } from "antd";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import EnterpriseForm from "../../components/CustomForms/EnterpriseLeadForm";
import StepWithBigNumber from "../../components-v2/StepWithBigNumber";
import EnterprisePartnersStyles from "../../styles/lp-styles/enterprise-partners-styles";

import { mainSection, steps } from "../../data/landing/enterprise-partners";
import SpotOnWhiteLogo from "../../images/svg/SpotOnLogoWhite.svg";
import AppetizeWhiteLogo from "../../images/svg/AppetizeLogoWhite.svg";
import SpotBackground from "../../images/svg/full-spot.svg";
import ogImage from "../../images/global_assets/og-image.png";
import stepConnectionTop from "../../images/svg/step-connection-top.svg";
import stepConnectionBottom from "../../images/svg/step-connection-bottom.svg";
import backgroundRight from "../../images/svg/vector-01.svg";
import backgroundLeft from "../../images/svg/vector-02.svg";

const { Title } = Typography;

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

export default function EnterpriseLeadPage() {
  const [topSectionRef, topSectionInView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (topSectionInView && !isMobileOnly) {
      controls.start("visible");
    }
  }, [topSectionInView]);

  return (
    <Layout
      className="enterprise-partners non-fixed-width"
      isLanding
      transparentHeader
      noTopOffset
      phoneNumber="+18775594225"
      phoneText="(877) 559-4225"
    >
      <SEO
        title="SpotOn Enterprise | Register a Lead"
        description="SpotOn Enterprise | Register a Lead"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <motion.section
        ref={topSectionRef}
        className="ep-top"
        animate={controls}
        variants={fadeVariants}
        initial={isMobileOnly ? "visible" : "hidden"}
        transition={{ duration: 0.8 }}
      >
        <div className="ep-top__logos">
          <img
            src={SpotOnWhiteLogo}
            alt="SpotOn logo"
            className="ep-top__logos--spoton"
          />

          <span className="ep-top__logos--sign">{"\uFE62"}</span>

          <img
            src={AppetizeWhiteLogo}
            alt="Appetize logo"
            className="ep-top__logos--appetize"
          />
          <img
            src={backgroundLeft}
            alt="background"
            className="ep-top__background-left"
          />
          <img
            src={backgroundRight}
            alt="background"
            className="ep-top__background-right"
          />
        </div>

        <Title className="ep-top__title">
          {mainSection.title}
          <span className="blue-sign">.</span>
        </Title>

        <p className="ep-top__title-description">{mainSection.description}</p>

        <div className="ep-top__form-wrapper">
          <EnterpriseForm
            header="Submit your SpotOn Enterprise lead"
            leadType="partner"
            formId="914dffa5-afd6-4500-8ff6-db39b7099c27"
            thankYouPath="/partners/thanks"
          />
        </div>

        <img
          className="ep-top__spot-background"
          src={SpotBackground}
          alt="logo background"
        />
      </motion.section>

      <section className="ep-steps">
        <StepWithBigNumber
          description={steps[0].description}
          title={steps[0].title}
          step={1}
        />

        <StepWithBigNumber
          className="odd"
          description={steps[1].description}
          title={steps[1].title}
          step={2}
        />

        <StepWithBigNumber
          description={steps[2].description}
          title={steps[2].title}
          step={3}
        />

        <img
          src={stepConnectionTop}
          alt="step connection"
          className="ep-steps__connection-top"
        />

        <img
          src={stepConnectionBottom}
          alt="step connection"
          className="ep-steps__connection-bottom"
        />
      </section>
      <EnterprisePartnersStyles />
    </Layout>
  );
}

// <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
//   {/* <div className="demo__content-wrapper"> */}
//   {/* <Row
//           justify="center"
//           align="middle"
//           style={{ fontSize: 42, marginTop: 40 }}
//         >
//           <img src={logoSpotOn} alt="spoton logo" />
//         </Row> */}
//   <Row
//     justify="center"
//     align="middle"
//     style={{
//       maxWidth: "90%",
//       margin: "auto",
//       marginTop: 20,
//       textAlign: "center",
//     }}
//   >
//     <Title level={1} className="openings-header">
//       SpotOn Enterprise
//     </Title>
//   </Row>
//   <Row justify="center" align="middle">
//     <Paragraph
//       className="normal-paragraph"
//       style={{ textAlign: "center", padding: 20 }}
//     >
//       Please obtain as much information as possible about the enterprise
//       brand before submitting your lead.
//     </Paragraph>
//   </Row>
//   <Row
//     justify="center"
//     align="middle"
//     style={{
//       maxWidth: "95%",
//       margin: "auto",
//       marginTop: -20,
//     }}
//   >
//     <EnterpriseForm
//       header="Submit your SpotOn Enterprise lead"
//       leadType="partner"
//       formId="914dffa5-afd6-4500-8ff6-db39b7099c27"
//       thankYouPath="/partners/thank-you"
//     />
//   </Row>
//   <Badges />
// </section>
