import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Row, Col, Select } from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import { navigate } from "gatsby";
import { AppetizeIndustryTypes } from "../../data/common-data";

import chevron from "../../images/svg/chevron_left.svg";
import { submitForm, ENTERPRISE_PARTNER } from "../../utils/firebase/forms";
import { getThankYouUrl } from "../../utils/url-utils";
import { zipValidationRegExp } from "../../constants";

const { Option } = Select;
const { TextArea } = Input;

const LPLeadForm = (props) => {
  const { leadType, formId, thankYouPath } = props;
  const form = useRef(null);
  const [loading, setLoading] = useState(false);
  const [zip, setZip] = useState("");
  const [zipError, setZipError] = useState("");
  const emailValidation = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const phoneValidation = new RegExp(
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  );

  const onFinish = async (values) => {
    setLoading(true);

    // const address = zipCodesMap.get(zip);
    if (!zipValidationRegExp.test(zip)) {
      setZipError("Please enter a valid zip code");
      return;
    }
    const dataToSend = {
      referred_first_name: values.aeFirstName,
      referred_last_name: values.aeLastName,
      referred_email: values.aeEmail,
      referred_by_pone_number: values.aePhone,
      company: values.company,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phone: values.phone,
      industry_enterprise: values.industry_enterprise,
      locations: values.locations,
      current_pos_provider: values.posProvider || "Unknown",
      // city: address.city || "",
      // state: address.state || "",
      zip,
      lead_notes: values.leadNotes,
      submitted_on: new Date(),
    };

    const result = await submitForm(dataToSend, ENTERPRISE_PARTNER);

    if (result) {
      setLoading(false);
      window.sessionStorage.setItem("Name", values.aeFirstName);
      const thankYouUrl =
        typeof window !== "undefined"
          ? getThankYouUrl(window.location.pathname)
          : null;

      if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
        // eslint-disable-next-line no-undef
        dataLayer.push({ event: `${leadType}-lead` });
      }

      navigate(thankYouUrl || thankYouPath);
    } else {
      setLoading(false);
    }
  };
  return (
    <section
      className="lead-form-wrapper"
      ref={form}
      style={{ marginBottom: 30 }}
    >
      <Form
        name="Enterprise Form"
        className="lead-form in-lp"
        layout="vertical"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        {/* Name */}
        <input type="hidden" name="Rasi Form" value="contact" />
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Account Executive First Name"
              name="aeFirstName"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name",
                  min: 2,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. John" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Account Executive Last Name"
              name="aeLastName"
              rules={[
                {
                  required: true,
                  message: "Please enter your last name",
                  min: 2,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. Snow" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Account Executive Email"
              name="aeEmail"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email",
                  pattern: emailValidation,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. john@company.com" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Account Executive Phone"
              name="aePhone"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid phone",
                  pattern: phoneValidation,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. (555) 555-1243 " />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Prospect Business Name"
              name="company"
              rules={[
                {
                  required: true,
                  message: "Please enter a business name",
                  min: 3,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. Football Arena Bar" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Prospect Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email",
                  pattern: emailValidation,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. arya@company.com" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Prospect Name"
              name="firstname"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid name",
                  min: 2,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. Arya" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Prospect Last Name"
              name="lastname"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid name",
                  min: 2,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. Stark" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Prospect Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid phone",
                  pattern: phoneValidation,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. (555) 555-1243 " />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <p className="label-business-type">Industry Type</p>
            <Form.Item
              style={{ overflow: "auto" }}
              name="industry_enterprise"
              rules={[
                {
                  required: true,
                  message: "Please select an industry",
                },
              ]}
            >
              <Select
                placeholder="Sports &amp; entertainment"
                style={{ width: "100%", color: "black !important" }}
              >
                {AppetizeIndustryTypes.map((type) => (
                  <Option key={type} value={type} className="drop-down__small">
                    {type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Number of Locatons"
              name="locations"
              rules={[
                {
                  required: true,
                  message: "Please enter number of location",
                  min: 1,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. 5" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Prospect Current POS provider"
              name="posProvider"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. Aloha" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Zip Code" name="zip">
              <Input
                size="large"
                placeholder="e.g. 10001"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid zip code",
                    min: 5,
                  },
                ]}
                onChange={(e) => setZip(e.target.value)}
              />
              <div className="text-[12px] text-danger">{zipError}</div>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              label="Additional Notes"
              name="leadNotes"
              rules={[
                {
                  required: true,
                  message: "Please provide details",
                  min: 5,
                },
              ]}
            >
              <TextArea
                rows={8}
                placeholder="Start Typing ..."
                style={{
                  border: "1px solid #77819c",
                  borderRadius: "4px !important",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="cta-primary"
              style={{ marginTop: 20 }}
              loading={loading}
              size="small"
            >
              Submit Lead
              <img
                src={chevron}
                alt="chevron icon"
                style={{ margin: "5px 5px 2px 10px" }}
              />
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </section>
  );
};

LPLeadForm.propTypes = {
  formId: PropTypes.string.isRequired,
  leadType: PropTypes.string.isRequired,
  thankYouPath: PropTypes.string,
};
LPLeadForm.defaultProps = {
  thankYouPath: "/thank-you",
};

export default LPLeadForm;
