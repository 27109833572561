import React, { useEffect } from "react";
import { string, number } from "prop-types";
import { isMobileOnly } from "react-device-detect";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import StepWithBigNumberStyles from "./styles";

const StepWithBigNumber = ({ className, step, title, description }) => {
  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView && !isMobileOnly) {
      controls.start("visible");
    }
  }, [inView]);

  const slideVariants = {
    hidden: { opacity: 0, x: className === "odd" ? 100 : -100 },
    visible: {
      opacity: 1,
      x: 0,
    },
  };

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  };

  return (
    <div className={`swbn ${className}`} ref={ref}>
      <motion.div
        className="swbn__step"
        animate={controls}
        variants={slideVariants}
        initial={isMobileOnly ? "visible" : "hidden"}
        transition={{ duration: 0.8 }}
      >
        <div className="swbn__step--number">{step}</div>
        <div className="swbn__step--label">
          <span>step</span>
          <span className="swbn__step--label-dot">.</span>
        </div>
      </motion.div>

      <motion.div
        className="swbn__info"
        animate={controls}
        variants={fadeVariants}
        initial={isMobileOnly ? "visible" : "hidden"}
        transition={{ duration: 1.3 }}
      >
        <div className="swbn__info--title">
          {title}
          <span className="blue-sign">.</span>
        </div>
        <div className="swbn__info--description">{description}</div>
      </motion.div>

      <StepWithBigNumberStyles />
    </div>
  );
};

StepWithBigNumber.propTypes = {
  className: string,
  step: number.isRequired,
  title: string.isRequired,
  description: string.isRequired,
};

StepWithBigNumber.defaultProps = {
  className: "",
};

export default StepWithBigNumber;
