export const mainSection = {
  title: `SpotOn Enterprise`,
  description: `SpotOn and Appetize are better together. And you can help. For every SpotOn Enterprise deal you refer here, you will get a % of the deal when it closes. So please share as much context as you can to ensure our success. We will keep you informed on the progress of the lead.
  `,
};

export const steps = [
  {
    title: `Outreach`,
    description: `Once you submit your referral the enterprise BDR team will reach out to you to understand more about the nature of your relationship with the contact, the conversations that have been had, any other context you can provide. They'll then reach out and make initial contact to start the sales process.`,
  },
  {
    title: `Sales Process`,
    description: `Once the business is qualified and the conversation moves into a more formal pipeline status, you will be notified by email and the Enterprise Sales Team will take over the sales process. Enterprise sales processes are generally measured in quarters and years, rather than days and weeks. The team will keep you apprised during relevant parts of the process.`,
  },
  {
    title: `Deal Won`,
    description: `Once the deal is closed and the business goes live, you will be notified via email and paid your referral bonus.`,
  },
];
